<template>
  <match-media v-slot="{ mobile }">
    <v-dialog
      :title="mobile ? 'Запрос на изменения' : ''"
      :visible.sync="localVisible"
      size="extra-large"
      class="owner-edit-pending-dialog"
    >
      <v-form body-scroll-lock-ignore class="owner-edit-pending-dialog__form" @submit.prevent="onSubmit">
        <template v-if="mobile" #header>
          <v-tabs v-model="activeTab" class="owner-edit-pending-dialog__form-tabs" primary>
            <v-tab name="left">
              {{ $options.TABS_LABELS_MAP[editMode ? 'SEND' : 'BEFORE_SENDING'] }}
            </v-tab>
            <v-tab name="right">
              {{ $options.TABS_LABELS_MAP[editMode ? 'EDIT' : 'SEND'] }}
            </v-tab>
          </v-tabs>
        </template>
        <div class="owner-edit-pending-dialog__form-body">
          <show-edit-pending-dialog-form-content-wrapper
            v-for="({ props, params }, index) in formColumns"
            v-show="params.show"
            :key="index"
            v-bind="props"
            class="owner-edit-pending-dialog__form-content-wrapper"
            :class="{ 'owner-edit-pending-dialog__form-content-wrapper--mobile': mobile }"
            :advert-options="advertOptions"
            :agent-options="agentOptions"
            :client-options="clientOptions"
            @change="setLocalShowData"
            @add-photo="addPhoto"
            @change-photos="changePhotos"
            @remove-photo="removePhoto"
            @select-adverts="onAdvertSearch"
            @select-clients="onClientSearch"
          />
        </div>

        <template #footer>
          <v-button class="owner-edit-pending-dialog__form-button" @click.prevent="editMode = !editMode">{{
            editMode ? 'Отменить' : 'Редактировать запрос'
          }}</v-button>
          <v-button
            v-if="editMode"
            :disabled="isFieldsNotChanged"
            class="owner-edit-pending-dialog__form-button"
            primary
            type="submit"
            >{{ isRoleAdmin ? 'Применить изменения' : 'Отправить обновленный запрос' }}
          </v-button>
        </template>
      </v-form>
    </v-dialog>
  </match-media>
</template>

<script>
import VDialog from '@/components/common/VDialog.vue'
import { advertsService, agentsService, customersService } from '@/services/http'
import { formatAccountName, formatAdvertName, formatAgentObject } from '@/utils/formatters'
import { cloneDeep, getFirstErrorForFields, isFieldsNotChanged } from '@/utils/common'
import { loadingService } from '@/services/loading'
import VButton from '@/components/common/VButton.vue'
import VForm from '@/components/form/VForm.vue'
import { mapGetters } from 'vuex'
import { MODULE_SESSION } from '@/store/modules/session/session.types'
import VTabs from '@/components/common/VTabs.vue'
import VTab from '@/components/common/VTab.vue'
import { MatchMedia } from 'vue-component-media-queries'
import ShowEditPendingDialogFormContentWrapper from '@/components/Shows/EditPendingDialogFormContentWrapper.vue'

const TABS_LABELS_MAP = {
  BEFORE_SENDING: 'До отправки',
  SEND: 'Отправлено',
  EDIT: 'Редактирование'
}

export default {
  TABS_LABELS_MAP,
  name: 'ShowsEditPendingDialog',
  components: {
    ShowEditPendingDialogFormContentWrapper,
    VTab,
    VTabs,
    VForm,
    VButton,
    VDialog,
    MatchMedia
  },
  props: {
    visible: { type: Boolean, required: true },
    showData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    draftData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      activeTab: 'right',
      localShowData: {},
      loading: false,
      errors: {},
      advertOptions: [],
      agentOptions: [],
      clientOptions: [],
      editMode: false
    }
  },
  inject: ['mediaQueries'],
  computed: {
    isFieldsNotChanged() {
      return isFieldsNotChanged(this.showData, this.localShowData, { isAdmin: this.isRoleAdmin, objectType: 'show' })
    },
    formColumns() {
      return [
        {
          props: {
            title: this.editMode ? TABS_LABELS_MAP.SEND : TABS_LABELS_MAP.BEFORE_SENDING,
            showData: this.editMode ? this.formatShowData(this.draftData) : this.formatShowData(this.showData),
            disableFields: true,
            editMode: false,
            backendErrors: null
          },
          params: {
            show: !this.mediaQueries.mobile || this.isFormType('left')
          }
        },
        {
          props: {
            title: this.editMode ? TABS_LABELS_MAP.EDIT : TABS_LABELS_MAP.SEND,
            showData: this.editMode ? this.localShowData : this.formatShowData(this.draftData),
            disableFields: !this.editMode,
            editMode: this.editMode,
            backendErrors: this.errors
          },
          params: {
            show: !this.mediaQueries.mobile || this.isFormType('right')
          }
        }
      ]
    },
    ...mapGetters({
      isRoleAdmin: `${MODULE_SESSION}/isRoleAdmin`
    }),
    localVisible: {
      get() {
        return this.visible
      },
      set(val) {
        this.editMode = false
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    editMode: {
      handler() {
        this.localShowData = this.formatShowData(this.draftData)
        this.errors = {}
      }
    },
    loading(val) {
      loadingService.setGlobalLoading(val)
    }
  },
  created() {
    if (this.isRoleAdmin) {
      this.selectAgents()
    }
    this.selectClients()
    this.selectAdverts()
  },
  methods: {
    changePhotos(photos) {
      this.localShowData.photos = photos
    },
    addPhoto(photo) {
      this.localShowData.photos.push(photo)
      this.localShowData.photosAdd.push(photo.id)
    },
    removePhoto(photo) {
      const { photosAdd, photos } = this.localShowData
      if (this.isOldPhoto(photo)) {
        this.localShowData.photosDelete.push(photo.id)
      }
      this.localShowData.photosAdd = photosAdd.filter(p => p.id !== photo.id)
      this.localShowData.photos = photos.filter(p => p.id !== photo.id)
    },
    isOldPhoto({ id }) {
      return !!this.showData?.photos?.find(photo => photo.id === id)
    },
    formatShowData(data) {
      const time = data?.showTime?.split(':') || null
      const [hours, minutes] = time || ['00', '00']

      const emptyShow = {
        account: null,
        agent: null,
        showDate: null,
        showTimeHours: hours,
        showTimeMinutes: minutes,
        advert: null,
        description: '',
        photos: [],
        photosAdd: [],
        photosDelete: []
      }

      return {
        ...emptyShow,
        ...cloneDeep(data),
        advert: { ...data?.advert, name: formatAdvertName(data?.advert, !data.advert) },
        account: data?.account
          ? { ...data?.account, name: formatAccountName(data, !data) }
          : { name: formatAccountName(data, !data) }
      }
    },
    onAdvertSearch({ query, loading }) {
      loading(true)
      this.selectAdverts(query).finally(() => {
        loading(false)
      })
    },
    onClientSearch({ query, loading }) {
      loading(true)
      this.selectClients(query).finally(() => {
        loading(false)
      })
    },
    isFormType(type) {
      return this.activeTab === type
    },
    setLocalShowData(newData) {
      this.localShowData = newData
    },
    selectAgents() {
      return agentsService.select().then(agentList => {
        this.agentOptions = agentList.map(agent => formatAgentObject(agent))
      })
    },
    selectAdverts(query) {
      return advertsService.select(query).then(advertsList => {
        this.advertOptions = advertsList.map(advert => {
          const { name, roundedPrice } = advert
          return {
            ...advert,
            name: `${name} - ${roundedPrice}`
          }
        })
      })
    },
    selectClients(query) {
      return customersService.select(query).then(results => {
        this.clientOptions = results
      })
    },
    onSubmit() {
      this.loading = true
      agentsService
        .updateShow({
          ...this.localShowData,
          showTime: `${this.localShowData.showTimeHours}:${this.localShowData.showTimeMinutes}`,
          id: this.showData.id
        })
        .then(newData => {
          this.$emit('submitted', newData)
          this.localVisible = false
        })
        .catch(error => {
          const flatErrors = { ...error.details, ...error.details.profile }
          this.errors = getFirstErrorForFields(flatErrors)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
