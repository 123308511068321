import axios from 'axios'

const { CancelToken } = axios

function flushPromises() {
  return new Promise(resolve => {
    setTimeout(resolve, 0)
  })
}

class CancelSourceManager {
  constructor() {
    this.store = {}
  }

  // eslint-disable-next-line class-methods-use-this
  create() {
    return CancelToken.source()
  }

  add({ id, cancelSource }) {
    if (!this.store[id]) {
      this.store[id] = []
    }
    const requestsWithId = this.store[id]
    requestsWithId.push(cancelSource)
  }

  remove({ id, cancelSource }) {
    const requestsWithId = this.store[id]
    this.store[id] = requestsWithId?.filter(requestCancelSource => requestCancelSource !== cancelSource)
  }

  async cancelById(id, withFlushPromises = false) {
    const cancelSources = this.store[id] ?? []
    cancelSources.forEach(cancelSource => cancelSource.cancel())
    delete this.store[id]

    if (withFlushPromises) {
      await flushPromises()
    }
  }

  withCancelSource(id, withFlushPromises = false) {
    return async callback => {
      await this.cancelById(id, withFlushPromises)

      const cancelSource = this.create()
      this.add({ id, cancelSource })

      try {
        return await callback({ cancelSource })
      } finally {
        this.remove({ id, cancelSource })
      }
    }
  }
}

export default new CancelSourceManager()
