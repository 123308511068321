<template>
  <match-media v-slot="{ mobile }">
    <div class="show-edit-pending-dialog-content">
      <h2 v-if="!mobile" class="show-edit-pending-dialog-content__title">{{ title }}</h2>
      <!--      todo: повторяется шаблон в editDialog, придумать как вынести в отдельный компонент-->
      <v-form-row>
        <v-form-field label="КЛИЕНТ" :error-message="disableFields ? '' : backendErrors.account">
          <template #default="{ validationErrors }">
            <v-select
              :value="localShowData.account"
              label="name"
              :disabled="disableFields"
              append-to-body
              :is-error="!!validationErrors.length"
              :options="clientOptions"
              :filterable="false"
              @search="onClientSearch"
              @input="updateLocalShowData('account', $event)"
            />
          </template>
        </v-form-field>
      </v-form-row>
      <v-form-row v-if="isRoleAdmin">
        <v-form-field label="АГЕНТ" :error-message="disableFields ? '' : backendErrors.agent">
          <template #default="{ validationErrors }">
            <v-select
              :value="localShowData.agent"
              label="name"
              :disabled="disableFields"
              append-to-body
              :is-error="!!validationErrors.length"
              :options="agentOptions"
              @input="updateLocalShowData('agent', $event)"
            />
          </template>
        </v-form-field>
      </v-form-row>
      <v-form-row>
        <v-form-field
          label="ОБЪЕКТ НЕДВИЖИМОСТИ"
          separate-label
          hide-errors
          :error-message="disableFields ? '' : backendErrors.advert"
        >
          <template #default="{ validationErrors }">
            <v-select
              :value="localShowData.advert"
              label="name"
              :is-error="!!validationErrors.length"
              :options="advertOptions"
              :disabled="disableFields"
              @search="onAdvertSearch"
              @input="updateLocalShowData('advert', $event)"
            ></v-select>
          </template>
        </v-form-field>
      </v-form-row>
      <v-form-row>
        <v-form-field label="ДАТА ПОКАЗА" :error-message="disableFields ? '' : backendErrors.showDate">
          <template #default="{ validationErrors }">
            <v-input-date
              :disabled="disableFields"
              :value="localShowData.showDate"
              class="shows-create-dialog__date-input"
              :clearable="false"
              format="DD.MM.YYYY"
              value-type="format"
              :is-error="!!validationErrors.length"
              @input="updateLocalShowData('showDate', $event)"
            />
          </template>
        </v-form-field>
      </v-form-row>
      <v-form-row>
        <v-form-field label="ВРЕМЯ ПОКАЗА" separate-label :error-message="disableFields ? '' : backendErrors.showTime">
          <template #default="{ validationErrors }">
            <div class="shows-create-dialog__time">
              <v-select
                :value="localShowData.showTimeHours"
                :disabled="disableFields"
                class="shows-create-dialog__time-select"
                label="name"
                append-to-body
                :is-error="!!validationErrors.length"
                :options="$options.HOURS"
                :clearable="false"
                @input="updateLocalShowData('showTimeHours', $event)"
              />
              <span>:</span>
              <v-select
                :value="localShowData.showTimeMinutes"
                :disabled="disableFields"
                class="shows-create-dialog__time-select"
                label="name"
                append-to-body
                :is-error="!!validationErrors.length"
                :options="$options.MINUTES"
                :clearable="false"
                @input="updateLocalShowData('showTimeMinutes', $event)"
              />
            </div>
          </template>
        </v-form-field>
      </v-form-row>
      <v-form-row>
        <v-form-field label="КОММЕНТАРИЙ" :error-message="disableFields ? '' : backendErrors.description">
          <template #default="{ validationErrors }">
            <v-textarea
              v-model="localShowData.description"
              :disabled="disableFields"
              :is-error="!!validationErrors.length"
              :rows="3"
            />
          </template>
        </v-form-field>
      </v-form-row>
      <v-form-row>
        <v-image-uploader-with-preview
          class="shows-create-dialog__image-uploader"
          :photos="localShowData.photos"
          :hide-input="disableFields"
          @change="changePhotos"
          @add="addPhoto"
        >
          <template #preview="{ photo }">
            <v-act-of-viewing-preview
              :blob-image="photo.image"
              :url="photo.url"
              :file-name="photo.fileName"
              :progress="photo.progress"
              :hide-delete-button="disableFields"
              @remove="removePhoto(photo)"
            ></v-act-of-viewing-preview>
          </template>
          <template #input-text>Загрузить фото акта просмотра</template>
        </v-image-uploader-with-preview>
      </v-form-row>
    </div>
  </match-media>
</template>

<script>
import VFormRow from '@/components/form/VFormRow.vue'
import VFormField from '@/components/form/VFormField.vue'
import VSelect from '@/components/common/VSelect.vue'
import { mapGetters } from 'vuex'
import { MODULE_SESSION } from '@/store/modules/session/session.types'
import { MatchMedia } from 'vue-component-media-queries'
import VInputDate from '@/components/common/VInputDate.vue'
import VTextarea from '@/components/common/VTextarea.vue'
import { HOURS, MINUTES } from '@/constants/timeOptions'
import VImageUploaderWithPreview from '@/components/common/VImageUploaderWithPreview.vue'
import VActOfViewingPreview from '@/components/common/VActOfViewingPreview.vue'

export default {
  HOURS,
  MINUTES,
  name: 'ShowEditPendingDialogFormContentWrapper',
  components: {
    VActOfViewingPreview,
    VImageUploaderWithPreview,
    VTextarea,
    VInputDate,
    MatchMedia,
    VSelect,
    VFormField,
    VFormRow
  },
  props: {
    title: { type: String, default: '' },
    showData: { type: Object, default: () => {} },
    disableFields: { type: Boolean, default: false },
    editMode: { type: Boolean, default: false },
    advertOptions: { type: Array, default: () => [] },
    agentOptions: { type: Array, default: () => [] },
    clientOptions: { type: Array, default: () => [] },
    backendErrors: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    ...mapGetters({
      isRoleAdmin: `${MODULE_SESSION}/isRoleAdmin`
    }),
    localShowData: {
      get() {
        return this.showData
      },
      set(newValue) {
        this.$emit('change', { ...this.localShowData, ...newValue })
      }
    }
  },
  methods: {
    changePhotos(photos) {
      this.$emit('change-photos', photos)
    },
    addPhoto(photo) {
      this.$emit('add-photo', photo)
    },
    removePhoto(photo) {
      this.$emit('remove-photo', photo)
    },

    onAdvertSearch(query, loading) {
      this.$emit('select-adverts', { query, loading })
    },
    onClientSearch(query, loading) {
      this.$emit('select-clients', { query, loading })
    },
    updateLocalShowData(key, value) {
      this.localShowData = { [key]: value }
    }
  }
}
</script>

<style scoped lang="scss">
.show-edit-pending-dialog-content {
  &__title {
    margin-bottom: 20px;
  }
}
</style>
