var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-table-wrapper',_vm._g({staticClass:"shows-table",attrs:{"infinite-id":_vm.infiniteId,"list":_vm.list,"infinite-loading":_vm.infiniteLoading,"table-height":_vm.tableHeight,"with-select":true,"only-single-select":!_vm.isRoleAdmin},scopedSlots:_vm._u([{key:"customColumns",fn:function(){return [_c('el-table-column',{attrs:{"prop":"show_date","label":"Дата и время","sortable":"custom","class-name":"shows-table__cell--date","min-width":"145"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_row = ref.row;
var showDate = ref_row.showDate;
var showTime = ref_row.showTime;
return [_vm._v(" "+_vm._s(showDate)+" "+_vm._s(showTime)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"status","sortable":"custom","label":"Статус","class-name":"shows-table__cell--status","min-width":"145"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var status = ref.row.status;
return [_c('v-tag-group',{attrs:{"value":status,"group":_vm.$options.SHOWS_STATUSES_GROUP},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var color = ref.color;
var text = ref.text;
return [_c('v-tag',{attrs:{"color":color}},[_vm._v(" "+_vm._s(text))])]}}],null,true)})]}}])}),_c('el-table-column',{attrs:{"prop":"name","sortable":"custom","label":"Имя покупателя","class-name":"shows-table__cell--name","min-width":"244","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_row = ref.row;
var clientName = ref_row.clientName;
var account = ref_row.account;
return [(clientName && account && account.id)?_c('v-button',{staticClass:"shows-table__client-link",attrs:{"is-text":"","to":{ name: 'customer-edit', params: { customerId: account.id } }}},[_vm._v(_vm._s(clientName))]):[_vm._v(_vm._s(clientName || '-')+" ")]]}}])}),_c('el-table-column',{attrs:{"prop":"phone","label":"Телефон","class-name":"shows-table__cell--phone","min-width":"143"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var clientPhone = ref.row.clientPhone;
return [_vm._v(" "+_vm._s(_vm.formatPhone(clientPhone) || '-')+" ")]}}])}),(_vm.isRoleAdmin)?_c('el-table-column',{attrs:{"prop":"agent","sortable":"custom","class-name":"shows-table__cell--agent","label":"Агент","min-width":"200","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var agent = ref.row.agent;
return [(agent)?_c('v-agent',{attrs:{"photo":agent.photo},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" "+_vm._s(agent.name)+" ")]):_c('span',[_vm._v("-")])]}}],null,false,1957114441)}):_vm._e(),_c('el-table-column',{attrs:{"prop":"advert","label":"Объект","class-name":"shows-table__cell--advert","min-width":"85"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var advert = ref.row.advert;
return [_c('v-adverts-popover',{attrs:{"adverts":[advert]}})]}}])}),_c('el-table-column',{attrs:{"prop":"advert_category","label":"Категория","class-name":"shows-table__cell--adverts-category","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var advert = ref.row.advert;
return [_vm._v(" "+_vm._s((advert && advert.category && advert.category.name) || '-')+" ")]}}])})]},proxy:true}])},_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }