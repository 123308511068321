<template>
  <div>
    <el-table
      ref="table"
      :class="tableClass"
      :data="list"
      :default-sort="defaultSort"
      :height="localTableHeight"
      :header-cell-class-name="headerCellClass"
      :header-row-class-name="headerRowClass"
      :row-class-name="tableRowClassName"
      :cell-class-name="cellClass"
      empty-text=" "
      @selection-change="handleSelectionChange"
      @sort-change="sort"
      @row-click="rowClick"
    >
      <el-table-column width="1" prop="overlay" class-name="table-wrapper__row-overlay">
        <template #default="{row,column}">
          <div
            v-if="!hideRowOverlay && row.hasPending"
            :style="{ width: $refs.table.bodyWidth }"
            class="table-wrapper__row-overlay-wrapper"
            @click.stop="column.property === 'overlay' && rowOverlayClick(row)"
          >
            <slot name="overlayContent">
              <p class="table-wrapper__row-overlay-text">Отправлен запрос на изменения. <span>Посмотреть</span></p>
            </slot>
          </div>
        </template>
      </el-table-column>
      <el-table-column width="1" prop="box-shadow" class-name="table-wrapper__row-box-shadow">
        <template #default="{row}">
          <div
            v-if="!row.hasPending"
            :style="{ width: $refs.table.bodyWidth }"
            class="table-wrapper__row-box-shadow-hover"
          />
        </template>
      </el-table-column>
      <el-table-column
        v-if="withSelect"
        align="center"
        type="selection"
        :selectable="isSelectable"
        prop="selectors"
        width="24"
        class-name="table-wrapper__cell--select"
        :label-class-name="headerSelectClass"
      >
      </el-table-column>
      <slot name="customColumns"></slot>
      <template #append>
        <infinite-loading
          :identifier="infiniteId"
          force-use-infinite-wrapper=".el-table__body-wrapper"
          @infinite="infiniteHandler"
        >
          <template #spinner>
            <div class="table-wrapper__spinner-wrapper">
              <v-loader
                :color="isNmh ? $options.MAIN_THEME_COLOR.nmh : $options.MAIN_THEME_COLOR.agentik"
                :active="infiniteLoading"
              ></v-loader>
            </div>
          </template>
          <template #no-more><span></span></template>
          <template #no-results><span></span></template>
        </infinite-loading>
      </template>
    </el-table>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import VLoader from '@/components/common/VLoader.vue'
import { mapGetters } from 'vuex'
import { MODULE_SESSION } from '@/store/modules/session/session.types'
import { MAIN_THEME_COLOR } from '@/constants/colors'

export default {
  MAIN_THEME_COLOR,
  name: 'VTableWrapper',
  components: {
    VLoader,
    InfiniteLoading
  },
  props: {
    tableClass: {
      type: String,
      default: 'table-wrapper'
    },
    headerCellClass: {
      type: String,
      default: 'table-wrapper__header-cell'
    },
    headerRowClass: {
      type: String,
      default: 'table-wrapper__header-row'
    },
    cellClass: {
      type: String,
      default: 'table-wrapper__cell'
    },
    rowClass: {
      type: String,
      default: 'table-wrapper__row'
    },
    list: {
      type: Array,
      default: () => {
        return []
      }
    },
    tableHeight: {
      type: Number,
      default: 500
    },
    infiniteId: {
      type: Number,
      required: true
    },
    infiniteLoading: {
      type: Boolean,
      default: false
    },
    hideRowOverlay: {
      type: Boolean,
      default: false
    },
    withSelect: {
      type: Boolean,
      default: false
    },
    onlySingleSelect: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localTableHeight: 0,
      selectedItemsIds: []
    }
  },
  computed: {
    ...mapGetters({
      isNmh: `${MODULE_SESSION}/isNmh`
    }),
    headerSelectClass() {
      return `table-wrapper__header-cell--header-select ${
        this.onlySingleSelect ? 'table-wrapper__header-cell--header-select-disabled' : ''
      }`
    },
    defaultSort() {
      const { orderBy } = this.$route.query
      let order = null
      let prop = orderBy
      if (orderBy) {
        if (orderBy.startsWith('-')) {
          order = 'descending'
          prop = orderBy.slice(1)
        } else {
          order = 'ascending'
        }
      }
      return { prop, order }
    }
  },
  watch: {
    tableHeight: {
      immediate: true,
      handler(val) {
        this.$nextTick(() => {
          this.localTableHeight = val
        })
      }
    }
  },
  methods: {
    tableRowClassName({ row }) {
      if (this.selectedItemsIds.find(id => id === row.id)) {
        return `${this.rowClass} table-wrapper__row--selected`
      }
      return this.rowClass
    },
    isSelectable(row) {
      return !row.hasPending
    },
    infiniteHandler($state) {
      this.$emit('next-page-request', $state)
    },
    sort({ order, prop }) {
      let sort = ''
      if (order !== null) {
        sort = order === 'ascending' ? `${prop}` : `-${prop}`
      } else {
        sort = null
      }
      this.$emit('change-sort', { orderBy: sort })
    },
    rowClick({ id }) {
      this.$emit('row-click', id)
    },
    rowOverlayClick(row) {
      this.$emit('edit', row)
    },
    handleSelectionChange(items) {
      if (this.onlySingleSelect && items.length > 1) {
        this.$refs.table.toggleRowSelection(items[0])
        items.shift()
      }
      this.selectedItemsIds = items.map(item => item.id)
      this.$emit('bulk-edit', items)
    }
  }
}
</script>
