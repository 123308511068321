<template>
  <match-media v-slot="{ desktop, tablet, mobile }">
    <aside class="shows__filters-mobile">
      <v-drawer :visible.sync="filtersVisible">
        <filters-mobile
          :default-filters="queryParams"
          :count="params.count"
          @close="filtersVisible = false"
          @apply-filters="applyFilters"
        />
      </v-drawer>
    </aside>

    <aside v-if="!mobile" class="shows__bulk-edit">
      <v-drawer position="right" without-background :visible.sync="bulkEditVisible">
        <shows-bulk-edit-dialog
          :shows="selectedShowsEdit"
          @close="bulkEditVisible = false"
          @delete="deleteShows"
          @submitted="editShowsSubmit"
        ></shows-bulk-edit-dialog>
      </v-drawer>
    </aside>

    <div class="shows">
      <div ref="showsTopWrapper" class="shows__top-wrapper">
        <div class="shows__header">
          <v-page-title class="shows__title">
            Мои показы
            <span class="shows__count">{{ params.count }}</span>
          </v-page-title>
          <v-suggest-search
            v-if="desktop"
            class="shows__searchrow"
            :min-number-length="$options.MINIMUM_SEARCH_LENGTH_BY_NUMBERS"
            :min-string-length="$options.MINIMUM_SEARCH_LENGTH_BY_STRING"
            :query-string="queryParams.phone || queryParams.query"
            :get-suggestions-method="getSearchSuggestionsList"
            @reset="resetSuggestions"
          >
            <template #searchResultItem="{item}">
              <customer-suggest-search-item
                :customer="item"
                @click="goToCustomerPage(item.id)"
              ></customer-suggest-search-item>
            </template>
          </v-suggest-search>
          <div class="shows__buttons">
            <v-button v-if="!mobile" class="shows__button shows__button--filters" @click="toggleFilters">
              <v-icon-filters class="shows__button-icon shows__button-icon--filters" />
              Фильтры
            </v-button>
            <v-button primary class="shows__button shows__button--add" @click="addShow">
              <v-icon-plus class="shows__icon-plus" />
              Добавить показ
            </v-button>
          </div>
        </div>

        <v-button v-if="mobile" class="shows__button shows__button--filters" @click="toggleFilters">
          <v-icon-filters class="shows__button-icon shows__button-icon--filters" />
          Фильтры
        </v-button>

        <v-suggest-search
          v-if="!desktop"
          class="shows__search"
          :min-number-length="$options.MINIMUM_SEARCH_LENGTH_BY_NUMBERS"
          :min-string-length="$options.MINIMUM_SEARCH_LENGTH_BY_STRING"
          :query-string="queryParams.phone || queryParams.query"
          :get-suggestions-method="getSearchSuggestionsList"
          @reset="resetSuggestions"
        >
          <template #searchResultItem="{item}">
            <customer-suggest-search-item
              :customer="item"
              @click="goToCustomerPage(item.id)"
            ></customer-suggest-search-item>
          </template>
        </v-suggest-search>
      </div>
      <div class="shows__content">
        <template v-if="!mobile">
          <shows-table
            :table-height="tableHeight"
            :infinite-loading="infiniteLoading"
            :list="list"
            :infinite-id="infiniteId"
            @edit="editShow"
            @next-page-request="nextPageRequest"
            @change-sort="applyFilters"
            @bulk-edit="bulkEdit"
          ></shows-table>
        </template>
        <template v-else>
          <shows-list-item
            v-for="show in list"
            :key="show.id"
            v-bind="show"
            @delete="deleteShows([show.id])"
            @pending-edit="editShow(show)"
            @edit="editShowMobile(show)"
          />
          <infinite-loading force-use-infinite-wrapper :identifier="infiniteId" @infinite="infiniteHandler">
            <template #no-more><span></span></template>
            <template #no-results><span></span></template>
          </infinite-loading>
        </template>
      </div>
      <shows-create-dialog :visible.sync="editDialogVisible" :show-data="showData" @submitted="editShowsSubmit" />
      <shows-edit-pending-dialog
        :visible.sync="editPendingDialogVisible"
        :draft-data="showDraftData"
        :show-data="showData"
        @submitted="editShowsSubmit"
      ></shows-edit-pending-dialog>
    </div>
  </match-media>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import VIconFilters from '@/components/icons/VFilters.vue'
import VIconPlus from '@/components/icons/VPlus.vue'
import FiltersMobile from '@/components/Shows/Filters/Mobile.vue'
import CustomerSuggestSearchItem from '@/components/Customer/SuggestSearchItem.vue'
import VPageTitle from '@/components/common/VPageTitle.vue'
import VButton from '@/components/common/VButton.vue'
import VDrawer from '@/components/common/VDrawer.vue'
import VSuggestSearch from '@/components/common/VSuggestSearch.vue'
import noticeService from '@/services/notification'
import confirmService from '@/services/confirmation'
import { agentsService, customersService } from '@/services/http'
import CancelSourceManager from '@/services/http/CancelSourceManager'
import { loadingService } from '@/services/loading'
// eslint-disable-next-line import/no-cycle
import redirectIfNetworkIssue from '@/router/utils'
import { MatchMedia } from 'vue-component-media-queries'
import { pluralizeNumeral } from '@/utils/pluralization'
import ShowsTable from '@/components/Shows/Table.vue'
import ShowsListItem from '@/components/Shows/ListItem.vue'
import { getCalculatingHeightTable } from '@/utils/calculatingHeightTable'
import ShowsCreateDialog from '@/components/Shows/CreateDialog.vue'
import { cloneDeep } from '@/utils/common'
import ShowsBulkEditDialog from '@/components/Shows/BulkEditDialog.vue'
import ShowsEditPendingDialog from '@/components/Shows/EditPendingDialog.vue'
import { mapGetters } from 'vuex'
import { MODULE_SESSION } from '@/store/modules/session/session.types'

const MINIMUM_SEARCH_LENGTH_BY_NUMBERS = 7
const MINIMUM_SEARCH_LENGTH_BY_STRING = 3

export default {
  MINIMUM_SEARCH_LENGTH_BY_NUMBERS,
  MINIMUM_SEARCH_LENGTH_BY_STRING,
  name: 'Shows',
  components: {
    CustomerSuggestSearchItem,
    ShowsListItem,
    ShowsTable,
    ShowsCreateDialog,
    ShowsBulkEditDialog,
    ShowsEditPendingDialog,
    InfiniteLoading,
    VSuggestSearch,
    VDrawer,
    VIconFilters,
    FiltersMobile,
    MatchMedia,
    VPageTitle,
    VIconPlus,
    VButton
  },
  data() {
    return {
      // eslint-disable-next-line vue/max-len
      infiniteId: +new Date(), // Рекомендованное значение по умолчанию +new Date() https://peachscript.github.io/vue-infinite-loading/api/#identifier
      infiniteLoading: false,
      tableHeight: 0,
      filtersVisible: false,
      loading: false,
      list: [],
      showData: {},
      showDraftData: {},
      params: {
        count: 0,
        offset: 0,
        limit: 10
      },
      editDialogVisible: false,
      editPendingDialogVisible: false,
      bulkEditVisible: false,
      selectedShowsEdit: []
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: `${MODULE_SESSION}/isRoleAdmin`
    }),
    queryParams() {
      return {
        phone: this.$route.query.phone || undefined,
        query: this.$route.query.query || undefined,
        categoryId: Number(this.$route.query.categoryId) || undefined,
        agentId: Number(this.$route.query.agentId) || undefined,
        priceLte: Number(this.$route.query.priceLte) || undefined,
        priceGte: Number(this.$route.query.priceGte) || undefined,
        dateTo: this.$route.query.dateTo || undefined,
        dateFrom: this.$route.query.dateFrom || undefined,
        status: this.$route.query.status || undefined,
        orderBy: this.$route.query.orderBy || '-show_date'
      }
    }
  },
  watch: {
    loading(val) {
      loadingService.setViewLoading(val)
    },
    editPendingDialogVisible(val) {
      if (!val) {
        this.showData = {}
        this.showDraftData = {}
      }
    },
    editDialogVisible(val) {
      if (!val) this.showData = {}
    }
  },
  created() {
    this.fetchShows()
  },
  mounted() {
    const { showsTopWrapper } = this.$refs
    this.tableHeight = getCalculatingHeightTable(showsTopWrapper)
  },
  beforeRouteUpdate(to, from, next) {
    this.$nextTick(() => {
      this.resetShowsList()
      this.fetchShows()
    })
    next()
  },
  methods: {
    bulkEdit(shows) {
      if (shows.length) {
        this.selectedShowsEdit = shows
        this.bulkEditVisible = true
      } else {
        this.selectedShowsEdit = []
        this.bulkEditVisible = false
      }
    },
    infiniteHandler($state) {
      this.nextPageRequest($state)
    },
    resetShowsList() {
      this.showData = {}
      this.showDraftData = {}
      this.params.offset = 0
      this.list = []
    },
    nextPageRequest(infinityState) {
      if (this.list.length) this.fetchShows({ infinityState })
    },
    formatShowsList({ list }) {
      return list.map(show => {
        if (show.account) {
          const formatShow = cloneDeep(show)
          const { phone, name } = show.account
          if (phone) formatShow.clientPhone = phone
          if (name) formatShow.clientName = name
          return formatShow
        }
        return show
      })
    },
    resetSuggestions() {
      this.updateQueryParams({ phone: undefined, query: undefined })
    },
    updateQueryParams(newParams) {
      this.$router.push({ query: { ...this.queryParams, ...newParams } })
    },
    toggleFilters() {
      this.filtersVisible = !this.filtersVisible
    },
    filtersSearch(filters) {
      this.updateQueryParams(filters)
    },
    applyFilters(filters) {
      this.filtersVisible = false
      this.updateQueryParams(filters)
    },
    addShow() {
      this.editDialogVisible = true
    },
    editShowMobile(show) {
      this.editDialogVisible = true
      this.showData = show
    },
    editShow(show) {
      this.showData = show
      this.fetchShowDraft(show.derivativeVersionId || show.versionId).then(() => {
        this.editPendingDialogVisible = true
      })
    },
    fetchShowDraft(versionId) {
      return agentsService.getShowDraft(versionId).then(draftData => {
        if (draftData.account) {
          const { phone, name } = draftData.account
          this.showDraftData = {
            ...draftData,
            clientPhone: phone || draftData.clientPhone,
            clientName: name || draftData.clientName
          }
        } else this.showDraftData = draftData
      })
    },
    goToCustomerPage(customerId) {
      this.$router.push({
        name: 'customer-edit',
        params: { customerId }
      })
    },
    getSearchSuggestionsList({ inputValue, fetchType }) {
      this.updateQueryParams(
        fetchType === 'number' ? { phone: inputValue, query: undefined } : { phone: undefined, query: inputValue }
      )
      const runWithCancel = CancelSourceManager.withCancelSource('fetch-customers-suggest-search', true)
      return runWithCancel(({ cancelSource }) => {
        const searchParam = fetchType === 'number' ? { phone: inputValue } : { query: inputValue }
        return customersService.getList({ cancelToken: cancelSource.token, ...searchParam })
      }).then(({ results }) => {
        return results
      })
    },
    fetchShows({ infinityState, ...params } = {}) {
      const runWithCancel = CancelSourceManager.withCancelSource('fetch-shows', true)

      const fetchParams = { ...this.queryParams, ...params }
      const { limit, offset } = this.params

      runWithCancel(({ cancelSource }) => {
        if (!infinityState) this.loading = true
        else this.infiniteLoading = true
        return agentsService.getShowsList({ limit, offset, cancelToken: cancelSource.token, ...fetchParams })
      })
        .then(({ count, results }) => {
          this.params = { ...this.params, count }
          if (results.length) {
            this.list.push(...this.formatShowsList({ list: results }))
            this.params.offset += this.params.limit
            if (infinityState) {
              infinityState.loaded()
            } else {
              this.infiniteId += 1 // сброс скролла на текущее значение
            }
            return
          }
          if (infinityState) infinityState.complete()
        })
        .catch(redirectIfNetworkIssue)
        .finally(() => {
          this.loading = false
          this.infiniteLoading = false
        })
    },
    editShowsSubmit() {
      this.resetShowsList()
      this.fetchShows()
    },
    deleteShows(ids) {
      const showsGenitiveCase = pluralizeNumeral(ids.length, 'показ', 'показы', 'показы')
      const successText = pluralizeNumeral(ids.length, 'Показ удалён', 'Показы удалены', 'Показы удалены')

      confirmService
        .ask({ title: `Вы точно хотите удалить ${showsGenitiveCase}?` })
        .then(() => {
          this.loading = true
          agentsService[this.isAdmin ? 'bulkDeleteShows' : 'deleteShow'](this.isAdmin ? ids : ids[0])
            .then(() => {
              this.resetShowsList()
              this.fetchShows()
              noticeService.success(successText)
            })
            .catch(() => noticeService.error(`Не удалось удалить ${showsGenitiveCase}`))
            .finally(() => {
              this.loading = false
            })
        })
        .catch(() => {})
    }
  }
}
</script>
