<template>
  <div class="v-table-row" v-on="$listeners">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'VTableRow'
}
</script>
