<template>
  <v-table-row class="shows-item">
    <div v-if="hasPending" class="shows-item__overlay-wrapper" @click="overlayClick">
      <p class="shows-item__overlay-text">Отправлен запрос на изменения. <span>Посмотреть</span></p>
    </div>
    <div class="shows-item__content">
      <v-tag-group class="shows-item__show-status" :value="status" :group="$options.SHOWS_STATUSES_GROUP">
        <template #default="{ color, text }">
          <v-tag :color="color"> {{ text }}</v-tag>
        </template>
      </v-tag-group>
      <div class="shows-item__cell shows-item__cell--date">{{ showDate }} {{ showTime }}</div>
      <div class="shows-item__advert">
        <div class="shows-item__cell shows-item__cell--advert-title">{{ advertTitle }}</div>
      </div>
      <v-button
        v-if="account && account.id"
        class="shows-item__cell shows-item__cell--client-name"
        is-text
        :to="{ name: 'customer-edit', params: { customerId: account.id } }"
        >{{ name }}</v-button
      >
      <div v-else class="shows-item__cell shows-item__cell--client-name">{{ name }}</div>

      <router-link :to="agentProfileLink" @click.stop>
        <v-avatar class="shows-item__cell shows-item__cell--agent-avatar" size="small" :src="agent.photo" />
      </router-link>

      <div class="shows-item__cell shows-item__cell--phone">{{ phoneMask }}</div>
    </div>

    <div class="shows-item__actions" @click.stop>
      <v-popover-actions class="shows-item__popover">
        <v-button-icon><v-icon-dots /> </v-button-icon>
        <template #actions="{ onClick }">
          <v-button-wide :href="`tel:${phone}`">Позвонить</v-button-wide>
          <v-button-wide :href="`https://wa.me/${phone.replace(/^8/, '+7')}`">Написать в WhatsApp</v-button-wide>
          <v-button-wide @click="onClick(edit)">Редактировать</v-button-wide>
          <v-button-wide @click="onClick(deleteShow)">Удалить</v-button-wide>
        </template>
      </v-popover-actions>
    </div>
  </v-table-row>
</template>

<script>
import VAvatar from '@/components/common/VAvatar.vue'
import VIconDots from '@/components/icons/VDots.vue'
import VButtonWide from '@/components/common/VButtonWide.vue'
import VTableRow from '@/components/common/VTableRow.vue'
import VPopoverActions from '@/components/common/VPopoverActions.vue'
import VButtonIcon from '@/components/common/VButtonIcon.vue'
import { formatPhone } from '@/utils/formatters'
import VTagGroup from '@/components/common/VTagGroup.vue'
import VTag from '@/components/common/VTag.vue'
import { SHOWS_STATUSES_GROUP } from '@/constants/showsStatuses'
import VButton from '@/components/common/VButton.vue'

export default {
  SHOWS_STATUSES_GROUP,
  name: 'ShowsListItem',
  components: {
    VButton,
    VTag,
    VTagGroup,
    VAvatar,
    VButtonIcon,
    VIconDots,
    VTableRow,
    VPopoverActions,
    VButtonWide
  },
  inheritAttrs: false,
  props: {
    advert: { type: Object, default: () => {} },
    account: { type: Object, default: () => {} },
    agent: { type: Object, default: () => {} },
    clientName: { type: String, default: '' },
    clientPhone: { type: String, default: '' },
    showDate: { type: String, required: true },
    showTime: { type: String, required: true },
    hasPending: { type: Boolean, default: false },
    status: { type: String, default: '' }
  },
  computed: {
    advertTitle() {
      const { name } = this.advert || '-'
      return name
    },
    name() {
      const { name } = this.account || ''
      return name || this.clientName || '-'
    },
    agentProfileLink() {
      // TODO add agent id parameter
      return { name: 'users' }
    },
    phone() {
      const { phone } = this.account || ''
      return phone || this.clientPhone || '-'
    },
    phoneMask() {
      return this.phone ? formatPhone(this.phone) : '-'
    }
  },
  methods: {
    overlayClick() {
      this.$emit('pending-edit')
    },
    deleteShow() {
      this.$emit('delete')
    },
    edit() {
      this.$emit('edit')
    }
  }
}
</script>
