export const SORT_OPTIONS_MAP = [
  { label: 'Сначала новые', value: '-show_date' },
  { label: 'Сначала старые', value: 'show_date' },
  { label: 'По объекту +', value: 'advert' },
  { label: 'По объекту -', value: '-advert' },
  { label: 'По статусу +', value: 'status' },
  { label: 'По статусу -', value: '-status' },
  { label: 'Категории от Я до А', value: '-advert_category' },
  { label: 'Категории от А до Я', value: 'advert_category' },
  { label: 'Сначала без агентов', value: '-agent' },
  { label: 'Сначала с агентами', value: 'agent' }
]
