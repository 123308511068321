<template>
  <div class="popover-wrapper" @click="stopPropagation">
    <v-popover
      :placement="placement"
      :disabled="disabled"
      :popover-inner-class="innerClass"
      :popover-arrow-class="innerArrowClass"
    >
      <div class="popover-wrapper__title">
        <slot />
      </div>
      <template #popover>
        <slot name="popover" :option="options"> </slot>
      </template>
    </v-popover>
  </div>
</template>

<script>
export default {
  name: 'VPopoverWrapper',
  props: {
    options: { type: Array, default: () => [] },
    isClickStop: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    popoverInnerClass: { type: String, default: '' },
    popoverInnerArrowClass: { type: String, default: '' },
    placement: { type: String, default: undefined }
  },
  computed: {
    innerClass() {
      return `${this.popoverInnerClass} tooltip-inner popover-inner`
    },
    innerArrowClass() {
      return `${this.popoverInnerArrowClass} tooltip-arrow popover-arrow`
    }
  },
  methods: {
    stopPropagation(event) {
      if (this.isClickStop) event.stopPropagation()
    }
  }
}
</script>
