<template>
  <div class="bulk-edit-dialog">
    <div class="bulk-edit-dialog__header">
      <button class="bulk-edit-dialog__close-button" @click="$emit('close')">
        <v-icon-cross />
      </button>
      <div class="bulk-edit-dialog__header-info">
        Редактирование
        <div class="bulk-edit-dialog__header-info-items-count">
          <slot name="itemsCount"></slot>
        </div>
      </div>
      <v-button-wide class="bulk-edit-dialog__reset-button" @click="$emit('reset')">
        Сброс
      </v-button-wide>
    </div>
    <div class="bulk-edit-dialog__body">
      <slot name="body"></slot>
    </div>
    <div class="bulk-edit-dialog__footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import VIconCross from '@/components/icons/VCross.vue'
import VButtonWide from '@/components/common/VButtonWide.vue'

export default {
  name: 'VBulkEditDialog',
  components: { VButtonWide, VIconCross }
}
</script>
