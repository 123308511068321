var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('match-media',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var mobile = ref.mobile;
return [_c('div',{staticClass:"show-edit-pending-dialog-content"},[(!mobile)?_c('h2',{staticClass:"show-edit-pending-dialog-content__title"},[_vm._v(_vm._s(_vm.title))]):_vm._e(),_c('v-form-row',[_c('v-form-field',{attrs:{"label":"КЛИЕНТ","error-message":_vm.disableFields ? '' : _vm.backendErrors.account},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-select',{attrs:{"value":_vm.localShowData.account,"label":"name","disabled":_vm.disableFields,"append-to-body":"","is-error":!!validationErrors.length,"options":_vm.clientOptions,"filterable":false},on:{"search":_vm.onClientSearch,"input":function($event){return _vm.updateLocalShowData('account', $event)}}})]}}],null,true)})],1),(_vm.isRoleAdmin)?_c('v-form-row',[_c('v-form-field',{attrs:{"label":"АГЕНТ","error-message":_vm.disableFields ? '' : _vm.backendErrors.agent},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-select',{attrs:{"value":_vm.localShowData.agent,"label":"name","disabled":_vm.disableFields,"append-to-body":"","is-error":!!validationErrors.length,"options":_vm.agentOptions},on:{"input":function($event){return _vm.updateLocalShowData('agent', $event)}}})]}}],null,true)})],1):_vm._e(),_c('v-form-row',[_c('v-form-field',{attrs:{"label":"ОБЪЕКТ НЕДВИЖИМОСТИ","separate-label":"","hide-errors":"","error-message":_vm.disableFields ? '' : _vm.backendErrors.advert},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-select',{attrs:{"value":_vm.localShowData.advert,"label":"name","is-error":!!validationErrors.length,"options":_vm.advertOptions,"disabled":_vm.disableFields},on:{"search":_vm.onAdvertSearch,"input":function($event){return _vm.updateLocalShowData('advert', $event)}}})]}}],null,true)})],1),_c('v-form-row',[_c('v-form-field',{attrs:{"label":"ДАТА ПОКАЗА","error-message":_vm.disableFields ? '' : _vm.backendErrors.showDate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-input-date',{staticClass:"shows-create-dialog__date-input",attrs:{"disabled":_vm.disableFields,"value":_vm.localShowData.showDate,"clearable":false,"format":"DD.MM.YYYY","value-type":"format","is-error":!!validationErrors.length},on:{"input":function($event){return _vm.updateLocalShowData('showDate', $event)}}})]}}],null,true)})],1),_c('v-form-row',[_c('v-form-field',{attrs:{"label":"ВРЕМЯ ПОКАЗА","separate-label":"","error-message":_vm.disableFields ? '' : _vm.backendErrors.showTime},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('div',{staticClass:"shows-create-dialog__time"},[_c('v-select',{staticClass:"shows-create-dialog__time-select",attrs:{"value":_vm.localShowData.showTimeHours,"disabled":_vm.disableFields,"label":"name","append-to-body":"","is-error":!!validationErrors.length,"options":_vm.$options.HOURS,"clearable":false},on:{"input":function($event){return _vm.updateLocalShowData('showTimeHours', $event)}}}),_c('span',[_vm._v(":")]),_c('v-select',{staticClass:"shows-create-dialog__time-select",attrs:{"value":_vm.localShowData.showTimeMinutes,"disabled":_vm.disableFields,"label":"name","append-to-body":"","is-error":!!validationErrors.length,"options":_vm.$options.MINUTES,"clearable":false},on:{"input":function($event){return _vm.updateLocalShowData('showTimeMinutes', $event)}}})],1)]}}],null,true)})],1),_c('v-form-row',[_c('v-form-field',{attrs:{"label":"КОММЕНТАРИЙ","error-message":_vm.disableFields ? '' : _vm.backendErrors.description},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validationErrors = ref.validationErrors;
return [_c('v-textarea',{attrs:{"disabled":_vm.disableFields,"is-error":!!validationErrors.length,"rows":3},model:{value:(_vm.localShowData.description),callback:function ($$v) {_vm.$set(_vm.localShowData, "description", $$v)},expression:"localShowData.description"}})]}}],null,true)})],1),_c('v-form-row',[_c('v-image-uploader-with-preview',{staticClass:"shows-create-dialog__image-uploader",attrs:{"photos":_vm.localShowData.photos,"hide-input":_vm.disableFields},on:{"change":_vm.changePhotos,"add":_vm.addPhoto},scopedSlots:_vm._u([{key:"preview",fn:function(ref){
var photo = ref.photo;
return [_c('v-act-of-viewing-preview',{attrs:{"blob-image":photo.image,"url":photo.url,"file-name":photo.fileName,"progress":photo.progress,"hide-delete-button":_vm.disableFields},on:{"remove":function($event){return _vm.removePhoto(photo)}}})]}},{key:"input-text",fn:function(){return [_vm._v("Загрузить фото акта просмотра")]},proxy:true}],null,true)})],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }