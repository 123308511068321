<template>
  <v-bulk-edit-dialog
    class="shows-bulk-edit-dialog"
    @close="close"
    @reset="updateShowEditModel(isSingleEdit ? shows[0] : {})"
  >
    <template #itemsCount>
      {{ showsCountText }}
    </template>
    <template #body>
      <v-form class="shows-bulk-edit-dialog__form" @submit.prevent="onSubmit">
        <template #default>
          <v-form-row v-if="isSingleEdit">
            <v-form-field label="КЛИЕНТ" :error-message="errors.account">
              <v-select
                v-model="showEditModel.account"
                label="name"
                append-to-body
                :filterable="false"
                :is-error="!!errors.account"
                :options="clientOptions"
                @search="onClientSearch"
              />
            </v-form-field>
          </v-form-row>

          <v-form-row v-if="isRoleAdmin">
            <v-form-field label="АГЕНТ">
              <v-select
                v-model="showEditModel.agent"
                label="name"
                append-to-body
                :options="agentOptions"
                :is-error="!!errors.agent"
              />
            </v-form-field>
          </v-form-row>
          <v-form-row>
            <v-form-field label="ОБЪЕКТ НЕДВИЖИМОСТИ" separate-label :error-message="errors.advert">
              <v-select
                v-model="showEditModel.advert"
                :is-error="!!errors.advert"
                label="name"
                :options="advertOptions"
                @search="onAdvertSearch"
              ></v-select>
            </v-form-field>
          </v-form-row>
          <v-form-row>
            <v-form-field label="ДАТА ПОКАЗА" :error-message="errors.showDate">
              <v-input-date
                v-model="showEditModel.showDate"
                :is-error="!!errors.showDate"
                class="shows-bulk-edit-dialog__form-date-input"
                :clearable="false"
                format="DD.MM.YYYY"
                value-type="format"
              />
            </v-form-field>
          </v-form-row>
          <template v-if="isSingleEdit">
            <v-form-row>
              <v-form-field label="ВРЕМЯ ПОКАЗА" separate-label :error-message="errors.showTime">
                <div class="shows-bulk-edit-dialog__form-time">
                  <v-select
                    v-model="showEditModel.showTimeHours"
                    class="shows-bulk-edit-dialog__form-time-select"
                    label="name"
                    append-to-body
                    :is-error="!!errors.showTime"
                    :options="$options.HOURS"
                    :clearable="false"
                  />
                  <span>:</span>
                  <v-select
                    v-model="showEditModel.showTimeMinutes"
                    class="shows-bulk-edit-dialog__form-time-select"
                    label="name"
                    append-to-body
                    :is-error="!!errors.showTime"
                    :options="$options.MINUTES"
                    :clearable="false"
                  />
                </div>
              </v-form-field>
            </v-form-row>
            <v-form-row>
              <v-form-field label="КОММЕНТАРИЙ" :error-message="errors.description">
                <v-textarea v-model="showEditModel.description" :rows="3" />
              </v-form-field>
            </v-form-row>
            <v-form-row>
              <v-image-uploader-with-preview
                class="shows-bulk-edit-dialog__image-uploader"
                :photos="showEditModel.photos"
                @change="changePhotos"
                @add="addPhoto"
              >
                <template #preview="{ photo }">
                  <v-act-of-viewing-preview
                    :blob-image="photo.image"
                    :url="photo.url"
                    :file-name="photo.fileName"
                    :progress="photo.progress"
                    @remove="removePhoto(photo)"
                  ></v-act-of-viewing-preview>
                </template>
                <template #input-text>Загрузить фото акта просмотра</template>
              </v-image-uploader-with-preview>
            </v-form-row>
          </template>
        </template>
        <template #footer>
          <v-button class="shows-bulk-edit-dialog__form-action" @click="cancelShows"
            >Отменить {{ showsCountActionText }}</v-button
          >
          <v-button
            :disabled="isSingleEdit && isFieldsNotChanged"
            class="shows-bulk-edit-dialog__form-action"
            primary
            type="submit"
            >Обновить {{ showsCountActionText }}
          </v-button>
        </template>
      </v-form>
    </template>
    <template #footer>
      <v-button class="shows-bulk-edit-dialog__action" is-text @click="deleteShows">{{
        `Удалить ${showsCountText}`
      }}</v-button>
    </template>
  </v-bulk-edit-dialog>
</template>

<script>
import VButton from '@/components/common/VButton.vue'
import { advertsService, agentsService, customersService } from '@/services/http'
import { pluralizeNumeral } from '@/utils/pluralization'
import { formatAccountName, formatAdvertName, formatAgentObject } from '@/utils/formatters'
import VForm from '@/components/form/VForm.vue'
import VFormField from '@/components/form/VFormField.vue'
import VFormRow from '@/components/form/VFormRow.vue'
import VSelect from '@/components/common/VSelect.vue'
import VBulkEditDialog from '@/components/common/VBulkEditDialog.vue'
import VInputDate from '@/components/common/VInputDate.vue'
import { mapGetters } from 'vuex'
import { MODULE_SESSION } from '@/store/modules/session/session.types'
import { cloneDeep, getFirstErrorForFields, isFieldsNotChanged } from '@/utils/common'
import VTextarea from '@/components/common/VTextarea.vue'
import { HOURS, MINUTES } from '@/constants/timeOptions'
import VImageUploaderWithPreview from '@/components/common/VImageUploaderWithPreview.vue'
import VActOfViewingPreview from '@/components/common/VActOfViewingPreview.vue'

export default {
  HOURS,
  MINUTES,
  name: 'ShowsBulkEditDialog',
  components: {
    VActOfViewingPreview,
    VImageUploaderWithPreview,
    VTextarea,
    VInputDate,
    VBulkEditDialog,
    VSelect,
    VFormRow,
    VFormField,
    VForm,
    VButton
  },
  props: {
    shows: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      errors: {},
      showEditModel: {},
      agentOptions: [],
      clientOptions: [],
      advertOptions: []
    }
  },
  computed: {
    isFieldsNotChanged() {
      return isFieldsNotChanged(this.shows[0], this.showEditModel, { isAdmin: this.isRoleAdmin, objectType: 'show' })
    },
    ...mapGetters({
      isRoleAdmin: `${MODULE_SESSION}/isRoleAdmin`
    }),
    showsIds() {
      return this.shows.map(show => show.id)
    },
    showsCount() {
      return this.shows.length
    },
    showsCountText() {
      const text = pluralizeNumeral(this.showsCount, 'показ', 'показа', 'показов')
      return `${this.showsCount} ${text}`
    },
    showsCountActionText() {
      return pluralizeNumeral(this.showsCount, 'показ', 'показы', 'показы')
    },
    isSingleEdit() {
      return this.showsCount === 1
    }
  },
  watch: {
    shows: {
      immediate: true,
      handler(newShowsArray) {
        if (this.isSingleEdit) {
          this.updateShowEditModel(newShowsArray[0])
        } else {
          this.updateShowEditModel({})
        }
      }
    }
  },
  created() {
    if (this.isRoleAdmin) {
      this.selectAgents()
    }
    this.selectClients()
    this.selectAdverts()
  },
  methods: {
    changePhotos(photos) {
      this.showEditModel.photos = photos
    },
    addPhoto(photo) {
      this.showEditModel.photos.push(photo)
      this.showEditModel.photosAdd.push(photo.id)
    },
    removePhoto(photo) {
      const { photosAdd, photos } = this.showEditModel
      if (this.isOldPhoto(photo)) {
        this.showEditModel.photosDelete.push(photo.id)
      }
      this.showEditModel.photosAdd = photosAdd.filter(p => p.id !== photo.id)
      this.showEditModel.photos = photos.filter(p => p.id !== photo.id)
    },
    isOldPhoto({ id }) {
      return !!this.shows[0]?.photos?.find(photo => photo.id === id)
    },
    cancelShows() {
      agentsService.bulkUpdateShows(this.showsIds, { showStatus: 'canceled' }).then(() => {
        this.$emit('submitted')
        this.close()
      })
    },
    onAdvertSearch(query, loading) {
      loading(true)
      this.selectAdverts(query).finally(() => {
        loading(false)
      })
    },
    selectAdverts(query) {
      return advertsService.select(query).then(advertsList => {
        this.advertOptions = advertsList.map(advert => {
          const { name, roundedPrice } = advert
          return {
            ...advert,
            name: `${name} - ${roundedPrice}`
          }
        })
      })
    },
    deleteShows() {
      this.$emit('delete', this.showsIds)
    },
    updateShowEditModel(model) {
      if (Object.keys(model).length) {
        const time = model.showTime?.split(':') || null
        const [hours, minutes] = time || ['00', '00']

        const emptyShow = {
          agent: null,
          showDate: null,
          showTimeHours: hours,
          showTimeMinutes: minutes,
          advert: null,
          description: '',
          account: null,
          photos: [],
          photosDelete: [],
          photosAdd: []
        }
        this.showEditModel = {
          ...emptyShow,
          ...cloneDeep(model),
          advert: {
            ...model?.advert,
            name: formatAdvertName(this.shows[0]?.advert, !this.isSingleEdit && !this.shows[0]?.advert)
          },
          account: model?.account
            ? { ...model?.account, name: formatAccountName(this.shows[0], !this.isSingleEdit) }
            : { name: formatAccountName(this.shows[0], !this.isSingleEdit) }
        }
      } else this.showEditModel = {}
      this.errors = {}
    },
    close() {
      this.updateShowEditModel({})
      this.$emit('close')
    },
    onSubmit() {
      const submitMethod = this.isSingleEdit
        ? agentsService.updateShow({
            ...this.showEditModel,
            showTime: `${this.showEditModel.showTimeHours}:${this.showEditModel.showTimeMinutes}`,
            changedReminderFields: {
              comment: this.shows[0].description !== this.showEditModel.description,
              date: this.shows[0].showDate !== this.showEditModel.showDate,
              time:
                this.shows[0].showTime !==
                [this.showEditModel.showTimeHours, this.showEditModel.showTimeMinutes].join(':')
            }
          })
        : agentsService.bulkUpdateShows(this.showsIds, this.showEditModel)

      submitMethod
        .then(() => {
          this.$emit('submitted')
          this.close()
        })
        .catch(error => {
          this.errors = getFirstErrorForFields(error.details)
        })
    },
    selectAgents() {
      return agentsService.select().then(agentList => {
        this.agentOptions = agentList.map(agent => formatAgentObject(agent))
      })
    },
    selectClients(query) {
      return customersService.select(query).then(res => {
        this.clientOptions = res
      })
    },
    onClientSearch(query, loading) {
      loading(true)
      this.selectClients(query).finally(() => {
        loading(false)
      })
    }
  }
}
</script>
