<template>
  <router-link :to="{ name: 'users' }" class="agent" v-on="$listeners">
    <v-avatar class="agent__avatar" size="small" :src="photo" />
    <div class="agent__name">
      <slot></slot>
    </div>
  </router-link>
</template>

<script>
import VAvatar from '@/components/common/VAvatar.vue'

export default {
  name: 'VAgent',
  components: { VAvatar },
  props: {
    photo: {
      type: String,
      default: ''
    }
  }
}
</script>
